import styled from "styled-components";

export const Container = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  
  #models{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 120px;

    >div{
      width: 25%;
      padding: 5px;
      margin-bottom: 10px;
      cursor: pointer;

      >img{
        width: 100%;
      }

      .modelsTitle{
        > * { 
          text-align: center;
          
          &:nth-child(1) {
            font-weight: bold;
            font-size: 30px;
            margin: 10px 0;
          }
          &:nth-child(2) {
            color: #CCC;
            font-size: 16px;
          }
        }
      }
    }

    
    @media (max-width: 980px){
      >div{
        width: 50%;
      }
    }
  
    @media (max-width: 600px){
      
      >div{
        width: 100%;
      }
    }

  }
`;