
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Auth from './Auth';
import Application from './Application';

import GlobalStyles from "./styles/GlobalStyles";


const PrivateRoute = ({ children, redirectTo }) => {

  const isAuthenticated = localStorage.getItem("token") !== null;
  console.log("isAuth: ", isAuthenticated);

  return isAuthenticated ? children : <Navigate to={redirectTo} />

}

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>

          <Route path="/admin/*" element={<PrivateRoute redirectTo="/">
            <Auth />
          </PrivateRoute>} />

          <Route path="/*" element={<Application />} />

        </Routes>
        <GlobalStyles />
      </BrowserRouter>
    </>
  );
}

export default App;
