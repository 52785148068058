import React, { useState, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useParams } from "react-router-dom";

import { Container } from './styles';
import Header from '../../components/Header'


function Component() {

  let { idModel } = useParams();

  const [itens, setItens] = useState([]);

  useEffect(async () => {
    let response = await fetch('/api/models.json').then(response => {
      return response.json();
    })

    setItens(response);
    console.log(response)

  }, []);

  return (
    <Container>
      <Header></Header>

      <div id="details">


        {
          !itens || itens.length ?
            itens.map((item, index) => {

              if (idModel == item.id) {
                const { name, location, images, id } = item;

                return (
                  <div id="detailsLeft">
                    <p id="detailsName">{name}</p>
                    <p id="detailsLocation">{location}</p>
                  </div>
                )
              }

            })
            : false
        }


        <div id="detailsRight">

          {
            !itens || itens.length ?
              itens.map((item, index) => {

                if (idModel == item.id) {
                  const { name, location, images, id } = item;

                  return (
                    images.map((item2, index2) => {
                      return (
                        <div key={index2} className="detailsImgs"><img src={item2} /></div>
                      );
                    })
                  )
                }

              })
              : false
          }

        </div>

      </div>

    </Container>
  );
}

export default Component;