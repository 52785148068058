import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 50px;
  flex-wrap: nowrap;
  width: 100%;
  position: fixed;
  background-color: #000;

  >a{
    width: 350px;
    outline-style: solid;
    outline-width: 1px;
    color: var(--primary);
    font-size: 16px;
    height: 30px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    min-width: 150px;
    text-align: center;
    line-height: 30px;
    text-decoration: none;
    
  }

  >svg{
    width: 7%;
    display: none;
  }

  @media (max-width: 980px){
    padding: 30px 30px;
  }
  
  @media (max-width: 600px){
    height: 80px;
    padding: 30px 10px;
  }

`;

export const Logo = styled.div`
  width: 15%;
  min-width: 200px;
  >a{
    width: 80%;
    > img{
      width: 100%;
    }
  }

  @media (max-width: 980px){
    width: 30%;
  }
  
  @media (max-width: 600px){
    >a{
      > img{
        margin-top: 3px;
        width: 90%;
      }
    }
  }
`;

export const Menu = styled.div`
  width: 80%;
  padding: 10px 150px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: var(--tertiary);
  font-size: 20px;
  font-weight: bold;

  >a{
    text-decoration: none;
  }

  @media (max-width: 1200px){
    padding-right: 40px;
  }
  
  @media (max-width: 980px){
    display: none;
  }
`;

export const MenuItens = styled.div`
  font-size: 14px;
  padding-left: 50px;
  cursor: pointer;

  @media (max-width: 1200px){
    padding-left: 40px;
  }

  @media (max-width: 1004px){
    padding-left: 20px;
  }
  
`;

