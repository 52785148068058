import styled from "styled-components";

export const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;

  #details{

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    
    #detailsLeft{
      width: 100%;
      margin-top: 120px;
   
      #detailsName{
        text-align: center;
        font-size: 40px;
        font-weight: bold;
      }
      #detailsLocation{
        text-align: center;
        font-size: 16px;
        margin-top: 20px;
      }
    }

    #detailsRight{
      width: 100%;
      margin-top: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-right: 30px;

      .detailsImgs{
        width: 33%;
        padding: 20px;
      }

      .detailsImgs>img{
        width: 100%;
      }
    }


    @media (max-width: 980px){   
      #detailsLeft{
      }
      #detailsRight{
          
        padding: 0;
        margin: 0;
        .detailsImgs{
          width: 50%;
          padding: 5px;
          margin: 0;
        }
      }
    }
  
    @media (max-width: 600px){
      #detailsLeft{
      }
      #detailsRight{
        
        .detailsImgs{
          width: 100%;
          padding: 0;
        }
      }
    }
  }
`;
