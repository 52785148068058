
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes, useParams } from "react-router-dom";

import LandingPage from './Layouts/LandingPage';
import ModelDetails from './Layouts/LandingPage/pages/ModelDetails';

function Application() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/models/:idModel" element={<ModelDetails />} />

{/*         
        <Route path="/models" element={<LandingPage />}>

          <Route path="/" element={<Home />} />
          <Route path="/agenda" element={<Schedule />} />
          <Route path="/clientes" element={<Customers />} />
          <Route path="/pacientes" element={<Patients />} />

          <Route path="/perfil" element={<UserProfile />} />

          <Route path="/adicionar-pacientes" element={<PatientsAdd />} />
          <Route path="/paciente" element={<PatientsProfile />} />

        </Route> */}

      </Routes>
      
    </>
  );
}

export default Application;
