import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: #FFF;
    font-family: 'Karla', sans-serif;
  }
  html, body, #root{
    min-width: 100%;
    min-height: 100%;
    background-color: #000;
  }
  *, button, input{
    border: 0;
    background: none;
    color: #FFF;
  }
  html {
    background: #FFF;
  }
  h1{
    color: #FFF;
  }
  :root{
    --primary: #FFF;
    --secondary: #302A38;
    --tertiary: #4A4451;
    --outline: #2F3336;

    --white: #D9D9D9;
    --green: #00e6a2;
    --h1_primary: 50px;
    
    @media (max-width: 1000px){
      --h1_primary: 40px;
    }
    @media (max-width: 450px){
      --h1_primary: 30px;
    }
  }
  

`;