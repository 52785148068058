import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { Container } from './styles';

import Header from './components/Header'
import { Carousel } from 'styled-icons/boxicons-regular';

function LandingPage() {

  const [itens, setItens] = useState([]);
  const [redirect, setRedirect] = useState(false);

  useEffect(async () => {
    let response = await fetch('api/models.json').then(response => {
      return response.json();
    })

    setItens(response);

  }, []);

  function redirectModel(id){
    setRedirect(<Navigate to={"/models/" + id} />)
  };

  return (
    <Container>
      {
        redirect?
          redirect
        : false
      }

      <Header></Header>


      <div id="models">

        {
          !itens || itens.length ?
            itens.map((item, index) => {
              
              const { name, location, images, id } = item;

              return (
                <div key={index} onClick={() => redirectModel(id)}>
                  <img src={images[0]}></img>
                  <div className="modelsTitle">
                    <p>{name}</p>
                    <p>{location}</p>
                  </div>
                </div>);
            })
            : false
        }

      </div>

    </Container>
  );
}

export default LandingPage;