import React from 'react';
import { Link, Navigate } from 'react-router-dom';

import { Container, Menu, Logo, MenuItens } from './styles';

function Header() {
  return (
    <Container>
      
      <Logo>
        <Link to="/"><img src="/images/logo.png" /></Link>
      </Logo>

      <Menu>
        <Link to="/"><MenuItens>Home</MenuItens></Link>
      </Menu>

      
      <a href="https://api.whatsapp.com/send?phone=5511987228954&text=Boa%20noite%20gostaria%20de%20conhecer%20uma%20modelo%20do%20site%20TopDreams" target="_blank">+55 11 98722-8954 / +55 11 98723-6697</a>

      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#7E57C2" />
        <path d="M22 11C22 14.3137 19.3137 17 16 17C12.6863 17 10 14.3137 10 11C10 7.68629 12.6863 5 16 5C19.3137 5 22 7.68629 22 11Z" fill="#7E57C2" />
        <path d="M4 26C4 26 10.3678 18 16 18C21.6322 18 28 26 28 26C28 26 26.3255 28.0059 25 29C22.0361 31.2229 19.7048 32 16 32C12.2952 32 9.96387 31.2229 7 29C5.67452 28.0059 4 26 4 26Z" fill="#7E57C2" />
      </svg>

    </Container>
  );
}

export default Header;