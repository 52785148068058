
import React from 'react';


function Auth() {
  return (
    <>
    </>
  );
}

export default Auth;
